.password-settings {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    background-color: #ffffff;
    max-width: 1100px;
    margin: -10px auto 15px;
    padding: 25px;
    box-shadow: 0 0 4px #b8bfc7;
}

.password-settings h3 {
    font-size: 18px;
    font-weight: normal;
}

.password-btn {
    background-color: #4aa1f3;
    color: #ffffff !important;
    font-weight: 600;
}